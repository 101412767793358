<template>
  <v-container
    fluid>
    <h2
      class="grey--text my-4 ml-3">
      Documentos de Movimentação
    </h2>

    <v-container
      fluid
      class="py-0 px-2">
      <DefaultForm
        ref="MovementDocumentForm"
        :formConfig="formConfig"
        :model="form"
      />
    </v-container>

    <v-container
      fluid
      class="py-0">
      <v-row
        class="d-flex justify-end">
        <v-col
          :cols="$vuetify.breakpoint.xsOnly ? 12 : 2">
          <v-btn
            x-large
            block
            @click="handleForm"
            color="primary">
            Salvar
          </v-btn>
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.xsOnly ? 12 : 2">
          <v-btn
            :class="{
              'mt-2': $vuetify.breakpoint.xsOnly
            }"
            outlined
            x-large
            block
            @click="back"
            color="primary">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />

    <Loader
      :overlay="loading"
    />
  </v-container>
</template>

<script>
import MovementRecordsMixin from '@/shared/mixins/movementRecords/movementRecordsMixin';
import MovementDocumentService from '@/services-http/sdi/MovementDocumentService';
import BeneficiaryTypeService from '@/services-http/sdi/BeneficiaryTypeService';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import DefaultForm from '@/components/Default/DefaultForm/DefaultForm.vue';
import DocumentMixin from '@/shared/mixins/document/documentMixin';
import ContractMixin from '@/shared/mixins/sdi/contractsMixin';
import Loader from '@/components/Loader/Loader.vue';
import Rules from '@/shared/validators/formRules';
export default {
  name: "MovementDocument",

  components: {
    SnackbarCustomize,
    DefaultForm,
    Loader,
  },

  data: () => ({
    loading: false,
    form: {
      financialGroupId: null,
      insuranceCarrierId: null,
      parentContractId: null,
      documentType: null,
      movementTypes: null,
      beneficiaryTypes: null,
      benefitTypes: [],
      kinshipIds: [],
      contractTypes: [],
      gracePeriod: false,
      mandatory: false,
    },
    financialGroups: [],
    kinships: [],
    documentTypes: [],
    insuranceCarriers: [],
    contracts: [],
    formContracts: [],
    contractTypes: [
      { id: "all", value: "Todos" },
      { id: "Coletivo Empresarial", value: "Coletivo Empresarial" },
      { id: "Coletivo por Adesão", value: "Coletivo por Adesão" },
      { id: "PME", value: "PME" },
    ],
    movementDocumentService: null,
    beneficiaryTypeService: null,
    rule: null,
    create: false,
    loading: false,
    loadContracts: false,
  }),

  mixins: [
    InsuranceCarriersMixin,
    FinancialGroupsMixin,
    MovementRecordsMixin,
    VerifyRoutesMixin,
    DocumentMixin,
    ContractMixin,
  ],

  watch: {
    "form.insuranceCarrierId": {
      immediate: true,
      deep: true,
      async handler(insuranceCarrierId) {
        if (!insuranceCarrierId) return;

        this.findContracts();

        if(this.form.parentContractId) return;

        await this.loadBenefitsByCarrierId(insuranceCarrierId);
      },
    },

    "form.parentContractId": {
      immediate: true,
      deep: true,
      async handler(parentContractId) {
        if (!parentContractId) return;

        await this.loadBenefitsByContractId(parentContractId);
      },
    },

    contracts: {
      immediate: true,
      deep: true,
      handler() {
        this.findContracts();
      }
    }
  },

  methods: {
    back() {
      this.$refs.MovementDocumentForm.reset();
      this.$refs.MovementDocumentForm.resetValidation();
      this.redirectRouter('MovementDocument', { isRHProtegido: this.isRHProtegido })
    },

    benefitTypesValidation() {
      if (this.form.benefitTypes.some((benefitTypeCode) => benefitTypeCode === 'all')) {
        this.form.benefitTypes = this.benefitTypes
          .filter((benefitType) => benefitType.benefitTypeCode !== 'all')
          .map((item) => item.benefitTypeCode);
      }
    },

    beneficiaryTypesValidation() {
      if (this.form.beneficiaryTypes && this.form.beneficiaryTypes.length === 0) {
        this.form.beneficiaryTypes = null;
      }
    },

    contractValidation() {
      this.form.contractTypes = null;
    },

    contractTypesValidation() {
      if (this.form.contractTypes.some((contractType) => contractType === 'all')) {
        this.form.contractTypes = ['all'];

        this.form.contractTypes = this.contractTypes
          .filter((contractType) => contractType.id !== 'all')
          .map((item) => item.id);
      }
    },

    async findContracts() {
      this.formContracts = this.contracts.filter(
        (contract) => contract.carrier_id.id === this.form.insuranceCarrierId
      );
    },

    async findContractsAndBenefitsByCarrierId() {
      this.benefitTypes = [];
      this.form.benefitTypes = null;

      this.findContracts();

      if (!this.form.insuranceCarrierId) return;

      await this.loadBenefitsByCarrierId(this.form.insuranceCarrierId);
    },

    async findBenefitsByContractId() {
      this.benefitTypes = [];
      this.form.benefitTypes = null;
      this.form.contractTypes = null;

      if (this.form.parentContractId) {
        await this.loadBenefitsByContractId(this.form.parentContractId);
      } else if (this.form.insuranceCarrierId) {
        await this.loadBenefitsByCarrierId(this.form.insuranceCarrierId);
      }
    },

    formatLists() {
      this.movementTypes.unshift({
        code: "all",
        description: "Selecionar todas as opções",
        name: "Selecionar todos",
        id: 999
      });

      this.kinships.unshift({
        code: "all",
        description: "Selecionar todos",
        id: "all"
      });

      this.benefitTypes.unshift({
        benefitTypeCode: "all",
        name: "Selecionar todos",
        id: 999
      });
    },

    async handleForm() {
      if (!this.$refs.MovementDocumentForm.$refs.form.validate()) return;

      this.loading = true;

      try {
        let payload = this.mountPaylaod(this.form);

        if (this.create === true) {
          await this.movementDocumentService.Post(payload);
        } else {
          payload.id = this.form.id,
          await this.movementDocumentService.Edit(payload);
        }

        this.$refs.SnackbarCustomize.open('success', 'Operação realizada com sucesso!');

        this.redirectRouter('MovementDocument', { isRHProtegido: this.isRHProtegido })
      } catch(err) {
        this.$refs.SnackbarCustomize.open('error', err);
      } finally {
        this.loading = false;
      }
    },

    kinshipValidation() {
      if (this.form.kinshipIds.some((kinshipId) => kinshipId === 'all')) {
        this.form.kinshipIds = this.kinships
          .filter((kinship) => kinship.code !== 'all')
          .map((item) => item.id);
      }
    },

    async loadBeneficiaryTypes() {
      await this.beneficiaryTypeService.FindAll()
        .then((response) => response.data)
        .then((result) => this.beneficiaryTypes = result);
    },

    async loadContractsByFinancialGroupId() {
      this.loadContracts = true;

      await this.getContractsByFinancialGroupIdVerifyRhProtegido(
        this.form.financialGroupId,
        this.isRHProtegido)
        .finally(() => this.loadContracts = false);

    },

    movementTypesValidation() {
      if (this.form.movementTypes.length === 0) {
        this.form.movementTypes = null;

        return;
      }

      if (this.form.movementTypes.some((movementType) => movementType === 'all')) {
        this.form.movementTypes = this.movementTypes
          .filter((movementType) => movementType.code !== 'all')
          .map((item) => item.code);
      }
    },

    mountPaylaod(form) {
      const kinships = form.kinshipIds.map(
        (kinshipId) => ({ id: kinshipId }));

      return {
        carrier: {
          id: form.insuranceCarrierId.toString(),
        },
        contract: form.parentContractId
          ? { id: form.parentContractId.toString() }
          : null,
        documentType: {
          id: form.documentType,
        },
        gracePeriod: form.gracePeriod,
        mandatory: form.mandatory,
        beneficiaryTypes: form.beneficiaryTypes,
        movementRecordTypes: form.movementTypes,
        benefitTypes: form.benefitTypes,
        kinships: kinships,
        contractTypes: !form.contractTypes ? [] : form.contractTypes,
      }
    },

    mountForm(form) {
      const kinshipIds = form.kinships
        && form.kinships.length > 0
          ? form.kinships.map((kinship) => kinship.id)
          : [];

      const contract = form.contract && form.contract.id ? this.contracts
        .find((contract) => contract.id === parseInt(form.contract.id)) : null;

      this.form = {
        id: form.id,
        insuranceCarrierId: parseInt(form.carrier.id),
        financialGroupId: !!contract ? contract.financial_group_id.id : null,
        parentContractId: form.contract ? parseInt(form.contract.id) : null,
        documentType: form.documentType.id,
        gracePeriod: form.gracePeriod ? form.gracePeriod : false,
        mandatory: form.mandatory ? form.mandatory : false,
        beneficiaryTypes: form.beneficiaryTypes,
        movementTypes: form.movementRecordTypes,
        benefitTypes: form.benefitTypes,
        kinshipIds: kinshipIds,
        contractTypes: !form.contractTypes ? [] : form.contractTypes,
      }
    },

    handlePageRefresh() {
      sessionStorage.setItem('form', JSON.stringify(this.form))
    },

    setSessionStoreData() {
      if (sessionStorage.getItem('form')) {
        this.form = JSON.parse(sessionStorage.getItem('form'));

        sessionStorage.removeItem('form')
      }
    },
  },

  computed: {
    degreeKinships() {
      return this.kinships.filter((kinship) =>
        kinship.id !== 22
        || kinship.code !== 'Titular'
        || kinship.description !== 'Titular');
    },

    formConfig() {
      const propsCol = {
        class: "text-subtitle-1 primary--text font-weight-medium pb-0",
        cols: "12",
        md: "3",
        lg: "3",
        xl: "3"
      };

      const propsAutocomplete = {
        placeholder: "Selecione",
        outlined: true,
        clearable: true,
        dense: true,
        color: "primary--text",
        itemColor: "primary--text",
      }

      const propsCard = {
        class: "rounded-lg pa-7"
      }

      return [
        {
					title: "",
					attrs: propsCard,
					fields: [
						{
              name: 'insuranceCarrierId',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
								label: 'Operadora*',
                itemText: "xipp_commercial_name",
                itemValue: "id",
                items: this.insuranceCarriers,
                rules: [this.rule.required],
							},
              on: {
								'change': () => this.findContractsAndBenefitsByCarrierId()
              }
						},
            {
              name: 'financialGroupId',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
								label: 'Grupo Financeiro',
                itemText: "name",
                itemValue: "id",
                items: this.financialGroups,
							},
              on: {
								'change': async () => await this.loadContractsByFinancialGroupId()
              }
						},
            {
              name: 'parentContractId',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
                ...propsAutocomplete,
                label: 'Contrato',
                itemText: "name",
                itemValue: "id",
                items: this.contracts,
                disabled: this.contracts.length === 0,
                loading: this.loadContracts,
							},
              on: {
								'change': () => this.findBenefitsByContractId()
              }
						},
            {
              name: 'documentType',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
                ...propsAutocomplete,
                itemText: "name",
                itemValue: "id",
                items: this.documentTypes,
                rules: [ this.rule.required ],
								label: 'Tipo de Documento*',
							},
						},
            {
              name: 'movementTypes',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                multiple: true,
                items: this.movementTypes,
                itemText: "name",
                itemValue: "code",
                rules: [ this.rule.required ],
								label: 'Tipos de Movimentações*',
							},
              on: {
								'change': () => this.movementTypesValidation()
              }
						},
            {
              name: 'beneficiaryTypes',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                itemText: "description",
                itemValue: "id",
                items: this.beneficiaryTypes,
                multiple: true,
                rules: [ this.rule.required ],
								label: 'Tipos de Beneficiários*',
							},
						},
            {
              name: 'benefitTypes',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                multiple: true,
                items: this.benefitTypes,
                itemText: "name",
                itemValue: "benefitTypeCode",
								label: 'Tipos de Benefício',
                loading: this.loadingBenefits
							},
						},
            {
              name: 'kinshipIds',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                multiple: true,
                items: this.degreeKinships,
                itemText: "description",
                itemValue: "id",
								label: 'Graus de Parentesco',
                disabled: !this.form.beneficiaryTypes || !this.form.beneficiaryTypes.some((beneficiaryType) => beneficiaryType === 'DEPENDENT')
							},
              on: {
								'change': () => this.kinshipValidation()
              }
						},
            {
              name: 'contractTypes',
							type: 'autocomplete',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                items: this.contractTypes,
                multiple: true,
                rules: this.form.parentContractId ? [] : [ this.rule.required ],
                itemText: "value",
                itemValue: "id",
                disabled: this.form.parentContractId ? true : false,
								label: 'Portes do Contrato',
							},
              on: {
								'change': () => this.contractTypesValidation()
              }
						},
            {
              name: 'gracePeriod',
							type: 'select',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                items: [
                  { id: true, value: "Sim" },
                  { id: false, value: "Não" },
                ],
                itemText: "value",
                itemValue: "id",
								label: 'Possui Carência',
							},
						},
            {
              name: 'mandatory',
							type: 'select',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsAutocomplete,
                items: [
                  { id: true, value: "Sim" },
                  { id: false, value: "Não" },
                ],
                itemText: "value",
                itemValue: "id",
								label: 'Obrigatório*',
							},
						},
					]
				},
      ]
    },
  },

  created() {
    this.isRHProtegido = this.$route.query.isRHProtegido;

    this.movementDocumentService = new MovementDocumentService();
    this.beneficiaryTypeService = new BeneficiaryTypeService();
    this.rule = new Rules();
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData();
  },

  async mounted() {
    this.loading = true;

    await Promise.all([
      this.getInsuranceCarriers(),
      this.loadBeneficiaryTypes(),
      this.getFinancialGroups(),
      this.loadDocumentTypes(),
      this.loadMovementTypes(),
      this.loadAllContracts(),
      this.loadKinships(),
    ]);

    this.formatLists();

    if (this.$route.query && this.$route.query.new && this.$route.query.new.toString() === "true") {
      this.create = true;
    } else {
      if (Object.keys(this.$route.params).length !== 0) {
        this.mountForm(this.$route.params)
      }
    }

    this.loading = false;
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },
}
</script>